import React from "react";
import styled from "styled-components";
import Avatar, { useAvatar, AvatarProvider } from "@components/Avatar";

function Playground() {
  return (
    <AvatarProvider size={300}>
      <PlaygroundContent />
    </AvatarProvider>
  );
}

function PlaygroundContent() {
  const {
    size,
    handleScatter,
    handlePulse,
    handleStart,
    handleStop,
    handleRotate,
    handleError,
    handleCenter,
    handleLeftCross,
    handleRightCross,
    testControls
  } = useAvatar()!;

  function sequenceHandler() {
    handleStart()
      .then(handlePulse)
      .then(handlePulse)
      .then(handlePulse)
      .then(handlePulse)
      .then(handlePulse)
      .then(handlePulse)
      .then(handlePulse)
      .then(handlePulse)
      .then(handleStop)
      .then(handleScatter)
      .then(handleRotate);
  }

  function lineHandler() {
    handleStart();
  }

  function stopHandler() {
    handleStop();
  }

  function scatterHandler() {
    handleScatter();
  }

  function rotateHandler() {
    handleRotate();
  }

  function errorHandler() {
    handleError();
  }

  function pulseHandler() {
    handlePulse();
  }

  function centerHandler() {
    handleCenter();
  }

  async function leftCrossHandler() {
    handleLeftCross();
  }

  function rightCrossHandler() {
    handleRightCross();
  }

  function testHandler() {
    testControls();
  }

  return (
    <Wrapper>
      <ButtonsContainer>
        <Button onClick={sequenceHandler}>full sequence</Button>
        <Button onClick={centerHandler}>center</Button>
        <Button onClick={lineHandler}>line</Button>
        <Button onClick={pulseHandler}>pulse</Button>
        <Button onClick={scatterHandler}>scatter</Button>
        <Button onClick={leftCrossHandler}>l-cross</Button>
        <Button onClick={rightCrossHandler}>r-cross</Button>
        <Button onClick={rotateHandler}>rotate</Button>
        <Button onClick={stopHandler}>stop rotate</Button>
        <Button onClick={errorHandler}>error</Button>
        <Button onClick={testHandler}>test</Button>
      </ButtonsContainer>
      <AvatarContainer>
        <Avatar />
      </AvatarContainer>

      <pre>{Math.random()}</pre>
    </Wrapper>
  );
}

export default Playground;

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
  padding-top: 16rem;
  grid-template-rows: auto minmax(0, 1fr);
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

const Button = styled.button`
  background: none;
  outline: none;
  border: none;
  font-size: 1.6rem;
  padding: 0.5rem 2rem;
  border-radius: 9999px;
  background: linear-gradient(to right, cornflowerblue, slateblue);
  color: white;
  cursor: pointer;
`;

const AvatarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #635ec0;
  background-size: 100px 100px;
  background-image: repeating-linear-gradient(
      0deg,
      transparent,
      transparent 99px,
      #ccc 99px,
      #ccc 100px
    ),
    repeating-linear-gradient(
      -90deg,
      transparent,
      transparent 99px,
      #ccc 99px,
      #ccc 100px
    );
`;

import React, {
  useReducer,
  useEffect,
  useContext,
  createContext,
  memo
} from "react";
import styled from "styled-components";
import { useFormContext } from "react-hook-form";
import useFormState from "@hooks/useFormState";
import {
  motion,
  animate,
  useAnimation,
  useSpring,
  useMotionValue,
  Variants,
  Transition,
  MotionValue,
  AnimationControls
} from "framer-motion";
import steps, { ChatStep } from "@utils/steps";

type UseDotsType = ReturnType<typeof useDot>;
interface Orbs {
  blue: UseDotsType;
  purple: UseDotsType;
  pink: UseDotsType;
  orange: UseDotsType;
}

const blueHex = "#88cafc";
const purpleHex = "#c3aef7";
const pinkHex = "#fa86bb";
const orangeHex = "#fb7b62";
const errorHex = "#ff6347";

type ContextProps = {
  orbs: Orbs;
  size: number;
  orbSize: number;
  containerControls: AnimationControls | undefined;
  handleScatter: () => Promise<any>;
  handleStart: () => Promise<any>;
  handlePulse: () => Promise<any>;
  handleStop: () => Promise<any>;
  handleRotate: () => Promise<any>;
  handleError: () => Promise<any>;
  handleCenter: () => Promise<any>;
  handleLeftCross: () => Promise<any>;
  handleRightCross: () => Promise<any>;
  testControls: () => Promise<any>;
};

const AvatarContext = createContext<ContextProps | undefined>(undefined);

function useDot(size: number) {
  const orbSize = size / 4.5;
  const mid = size / 2 - orbSize / 2;

  const x = useSpring(mid);
  const y = useSpring(mid);
  const scale = useSpring(1);
  const controls = useAnimation();

  return { x, y, scale, controls } as const;
}

export function AvatarProvider({
  children,
  size = 100
}: {
  children: React.ReactNode;
  size?: number;
}) {
  const orbs = {
    blue: useDot(size),
    purple: useDot(size),
    pink: useDot(size),
    orange: useDot(size)
  };

  const containerControls = useAnimation();
  const { controls: blueControls, ...blue } = orbs.blue;
  const { controls: purpleControls, ...purple } = orbs.purple;
  const { controls: pinkControls, ...pink } = orbs.pink;
  const { controls: orangeControls, ...orange } = orbs.orange;

  const orbSize = size / 4.5;
  const orbOffset = orbSize / 2;

  const column = size / 4;

  const midY = size / 2 - orbOffset;
  const columnOffset = (column - orbSize) / 2;

  const transition: Transition = { duration: 0.1 };

  function handleScatter() {
    return Promise.all([
      purpleControls.start({
        scale: 1.6,
        x: midY,
        y: midY,
        background: purpleHex
      }),

      pinkControls.start({
        scale: 0.9,
        x: size * 0.33 - orbOffset - (orbSize * 0.9) / 2,
        y: midY,
        background: pinkHex
      }),

      blueControls.start({
        scale: 0.9,
        x: size * 0.67 - orbOffset - (orbSize * 0.9) / 2,
        y: size * 0.67 - orbOffset + (orbSize * 0.9) / 2,
        background: blueHex
      }),

      orangeControls.start({
        scale: 0.9,
        x: size * 0.33 - orbOffset + (orbSize * 0.9) / 2,
        y: size * 0.33 - orbOffset - (orbSize * 0.9) / 2,
        background: orangeHex
      })
    ]);
  }

  async function handleStart() {
    return Promise.all([
      pinkControls.start(
        {
          x: column * 0 + columnOffset,
          y: midY,
          scale: 1,
          background: pinkHex
        },
        { transition }
      ),
      orangeControls.start(
        {
          x: column * 1 + columnOffset,
          y: midY,
          scale: 1,
          background: orangeHex
        },
        { transition }
      ),
      purpleControls.start(
        {
          x: column * 2 + columnOffset,
          y: midY,
          scale: 1,
          background: purpleHex
        },
        { transition }
      ),
      blueControls.start(
        {
          x: column * 3 + columnOffset,
          y: midY,
          scale: 1,
          background: blueHex
        },
        { transition }
      )
    ]);
  }

  function handlePulse() {
    return new Promise<void>(async (resolve) => {
      await pinkControls.start({ y: midY + orbSize, transition });
      await orangeControls.start({ y: midY + orbSize, transition });
      await purpleControls.start({ y: midY + orbSize, transition });
      await blueControls.start({ y: midY + orbSize, transition });

      await pinkControls.start({ y: midY, transition });
      await orangeControls.start({ y: midY, transition });
      await purpleControls.start({ y: midY, transition });
      await blueControls.start({ y: midY, transition });

      resolve();
    });
  }

  function handleStop() {
    return new Promise<void>((resolve) => {
      containerControls.stop();
      containerControls.start({ rotate: 0 });
      orangeControls.stop();
      pinkControls.stop();
      purpleControls.stop();
      blueControls.stop();

      resolve();
    });
  }

  function handleRotate() {
    return containerControls.start({
      rotate: 360,
      transition: {
        ease: "linear",
        repeat: Infinity,
        duration: 2
      }
    });
  }

  function handleError() {
    return Promise.all([
      pinkControls.start({
        scale: 0.9,
        x: midY,
        y: (orbSize * 0.9) / 2 + orbSize * 0.9 * 0,
        background: errorHex
      }),

      orangeControls.start({
        scale: 0.9,
        x: midY,
        y: (orbSize * 0.9) / 2 + orbSize * 0.9 * 1,
        background: errorHex
      }),

      purpleControls.start({
        scale: 0.9,
        x: midY,
        y: (orbSize * 0.9) / 2 + orbSize * 0.9 * 2,
        background: errorHex
      }),

      blueControls.start({
        scale: 0.9,
        x: midY,
        y: (orbSize * 0.9) / 2 + orbSize * 0.9 * 3,
        background: errorHex
      })
    ]);
  }

  async function handleLeftCross() {
    const response = Promise.all([
      pinkControls.start({
        scale: 1,
        x: column * 0 + columnOffset,
        y: column * 0 + columnOffset,
        background: pinkHex
      }),

      orangeControls.start({
        scale: 1,
        x: column * 1 + columnOffset,
        y: column * 1 + columnOffset,
        background: orangeHex
      }),

      purpleControls.start({
        scale: 1,
        x: column * 2 + columnOffset,
        y: column * 2 + columnOffset,
        background: purpleHex
      }),

      blueControls.start({
        scale: 1,
        x: column * 3 + columnOffset,
        y: column * 3 + columnOffset,
        background: blueHex
      })
    ]);

    return response;
  }

  function handleRightCross() {
    const response = Promise.all([
      pinkControls.start({
        scale: 1,
        x: column * 0 + columnOffset,
        y: column * 3 + columnOffset,
        background: pinkHex
      }),

      orangeControls.start({
        scale: 1,
        x: column * 1 + columnOffset,
        y: column * 2 + columnOffset,
        background: orangeHex
      }),

      purpleControls.start({
        scale: 1,
        x: column * 2 + columnOffset,
        y: column * 1 + columnOffset,
        background: purpleHex
      }),

      blueControls.start({
        scale: 1,
        x: column * 3 + columnOffset,
        y: column * 0 + columnOffset,
        background: blueHex
      })
    ]);

    return response;
  }

  function handleCenter() {
    return Promise.all([
      pinkControls.start({
        scale: 1,
        x: midY,
        y: midY,
        background: pinkHex
      }),

      orangeControls.start({
        scale: 1,
        x: midY,
        y: midY,
        background: orangeHex
      }),

      purpleControls.start({
        scale: 1,
        x: midY,
        y: midY,
        background: purpleHex
      }),

      blueControls.start({
        scale: 1,
        x: midY,
        y: midY,
        background: blueHex
      })
    ]);
  }

  async function testControls() {
    const pinkOne = pinkControls.start({
      scale: 1,
      x: column * 0 + columnOffset,
      y: column * 0 + columnOffset,
      background: pinkHex,
      transition: {}
    });

    const blueOne = blueControls.start({
      scale: 1,
      x: column * 3 + columnOffset,
      y: column * 3 + columnOffset,
      background: blueHex,
      transition: {}
    });

    await Promise.all([pinkOne, blueOne]);
    console.log("Animation1 finished");

    const pinkTwo = pinkControls.start({
      scale: 1,
      x: column * 0 + columnOffset,
      y: column * 3 + columnOffset,
      background: pinkHex,
      transition: {
        delay: 0.25
      }
    });

    const blueTwo = blueControls.start({
      scale: 1,
      x: column * 3 + columnOffset,
      y: column * 0 + columnOffset,
      background: blueHex,
      transition: {
        delay: 0.25
      }
    });

    await Promise.all([pinkTwo, blueTwo]);

    console.log("Animation2 finished");

    return Promise.all([]);
  }

  const options = {
    orbs,
    orbSize,
    size,
    containerControls,
    handleScatter,
    handleStart,
    handlePulse,
    handleStop,
    handleRotate,
    handleError,
    handleCenter,
    handleLeftCross,
    handleRightCross,
    testControls
  };

  return (
    <AvatarContext.Provider value={options}>{children}</AvatarContext.Provider>
  );
}

export function useAvatar() {
  const context = useContext(AvatarContext);

  return context;
}

function Avatar({ isActive = true }: { isActive?: boolean }) {
  const {
    orbs,
    orbSize,
    size,
    containerControls,
    handleScatter,
    handleRotate,
    handleStop
  } = useAvatar()!;

  const { controls: blueControls, ...blue } = orbs.blue;
  const { controls: purpleControls, ...purple } = orbs.purple;
  const { controls: pinkControls, ...pink } = orbs.pink;
  const { controls: orangeControls, ...orange } = orbs.orange;

  // useEffect(() => {
  //   if (isActive === false) {
  //     console.log("hmm");
  //     handleScatter();
  //     handleStop();
  //   }
  // }, [isActive]);

  useEffect(() => {
    handleScatter();
  }, []);

  useEffect(() => {
    if (isActive === false) {
      handleStop();
    } else {
      handleRotate();
    }
  }, [isActive]);

  return (
    <DotsContainer
      style={{
        width: size,
        height: size,
        // @ts-ignore
        ["--base-size"]: `${orbSize}px`
      }}
      animate={containerControls}
    >
      <PurpleDot style={purple} animate={purpleControls} />
      <PinkDot style={pink} animate={pinkControls} />
      <BlueDot style={blue} animate={blueControls} />
      <OrangeDot style={orange} animate={orangeControls} />
    </DotsContainer>
  );
}

export default memo(Avatar);

const DotsContainer = styled(motion.div)`
  /* --base-size: 84px; */
  /* --blue: linear-gradient(50.66deg, #7ecefd 20.28%, #91c2ff 94.25%);
  --purple: linear-gradient(140.66deg, #b3b3ff 18.64%, #d9a1f2 92.61%);
  --pink: linear-gradient(230.66deg, #f78dd3 5.75%, #ff7da9 79.72%);
  --orange: linear-gradient(320.66deg, #ff777a 7.39%, #fc7d49 81.36%); */

  --blue: #88cafc;
  --purple: #c3aef7;
  --pink: #fa86bb;
  --orange: #fb7b62;

  position: relative;
  will-change: transform;
`;

const Dot = styled(motion.div)`
  width: var(--base-size);
  height: var(--base-size);
  border-radius: 50%;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
  position: absolute;
`;

const BlueDot = styled(Dot)`
  background: #88cafc;
`;

const PurpleDot = styled(Dot)`
  background: #c3aef7;
  z-index: 1;
`;

const PinkDot = styled(Dot)`
  background: #fa86bb;
`;

const OrangeDot = styled(Dot)`
  background: #fb7b62;
`;

/*
 // const { watch, formState } = useFormContext();

  // const step = steps[currentStep] as ChatStep;

  // const current = step?.questions?.[currentQuestion];

  // const { formName } = current;
  // const { touched } = formState;

  // const defaultValue = form[formName];
  // const replicatedValue = watch(formName) ?? "";

  // const hasBeenTouched = !!touched[formName];
  // const hasEnteredText = !!replicatedValue;
  // const hasPreviousEntry = !!defaultValue;

  // const shouldSkip =
  //   metaData?.[currentStep]?.[currentQuestion]?.timeSent !== undefined;

  // const shouldSkip = hasBeenTouched || hasEnteredText || hasPreviousEntry;

  // useEffect(() => {
  //   const current = steps[currentStep];

  //   if (current.type !== "chat") {
  //     handleIdle();
  //     return;
  //   }

  //   const { formName } = current.questions[currentQuestion];

  //   if (!form[formName]) {
  //     handleStart();
  //   } else {
  //     handleIdle();
  //   }
  // }, [handleStart, handleIdle, currentStep, currentQuestion]);
*/
